.contato {
  width: 100%;
  height: auto;
  font-size: 3rem;

  background: var(--claro);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: var(--preto);
  font-size: 10rem;
}

.titleContato {
  padding-top: 50px;
  text-align: center;
  padding-left: 0.2em;
}

.titleContato h5,
.endereco-contato h5 {
  padding-bottom: 0.3em;
  font-size: 0.25em;
}

.titleContato p,
.endereco-contato p {
  font-size: 20px;
}

.cardsContato {
  padding-top: 0.5em;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-bottom: 0.7em;
}
.card-email,
.card-face,
.card-whats,
.card-insta,
.card-tel {
  margin-top: 10px;
  color: var(--marrom);
  display: flex;
  align-items: center;
  width: 200px;
  height: 250px;
  background: var(--claro);
  flex-direction: column;
  justify-content: space-evenly;
  border: solid 1px var(--marrom);
  border-radius: 0px 30px 0px 30px;
}
.iconeCard-face,
.iconeCard-insta,
.iconeCard-whats,
.iconeCard-tel,
.iconeCard-email {
  width: 5rem;
}
.iconeCard-whats:hover,
.card-whats:hover {
  color: green;
}
.iconeCard-face:hover,
.card-face:hover {
  color: blue;
}
.iconeCard-inst:hover,
.card-insta:hover {
  color: crimson;
}
.iconeCard-email:hover,
.card-email:hover {
  color: var(--secundary);
}
.card-insta p,
.card-whats p,
.card-email p,
.card-face p {
  font-size: 26px;
  text-decoration: none;
}
.card-tel p {
  font-size: 26px;
  text-decoration: none;
  font-weight: bold;
}
.card a {
  font-size: 16px;
  text-decoration: none;
}
a:link {
  text-decoration: none;
  color: black;
}
a:hover {
  text-decoration: none;
  color: black;
  font-weight: bold;
}
a:hover {
  text-decoration: none;
  color: black;
  font-weight: bold;
}
.endereco-contato {
  display: flex;
  align-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}
.subTitleContato {
  padding-left: 0.2em;
  text-align: center;
}
.mapa {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  /* padding-bottom: 0em !important; */
  padding-top: 0.2em !important;
  padding: 0;
}
.iframa-mapa {
  border: 1px solid #000000;
}
