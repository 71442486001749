/*Desktop View*/
.header {
  display: flex;
  background: var(--claro);
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
  height: 69px;
  position: -webkit-sticky; /* Necessário para funcionar no Safari */
  position: sticky;
  top: 0;
  z-index: 1000;
  border-bottom: solid 4px var(--marromclaro);
}
.logo-nav {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 14px;
  font-weight: 500;
}
.logo-nav a {
  color: var(--preto);
  text-decoration: none;
}
.logo-nav a:hover {
  color: var(--preto);
  font-weight: bold;
}
.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
}
.nav-right {
  display: flex;
  margin-left: auto;
  list-style: none;
  font-size: 22px;
}
.nav-right li {
  padding: 0px 10px;
}
.nav-right li a svg {
  color: var(--preto);
}

.logo {
  width: 150px;
  height: auto;
}

.nav-options {
  padding-left: 45px;
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 20px;
  list-style-type: none;
}
.mobile-option {
  display: none;
}

.option :hover {
  color: var(--claro);
}

.mobile-menu {
  display: none;
}
@media (max-width: 858px) {
  .nav-right {
    display: none;
  }
}
@media (max-width: 648px) {
  /*Mobile View */
  .header {
    padding: 0px 10px;
  }

  .nav-options {
    display: flex;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 70px;
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
    flex-direction: column;
  }
  .nav-right {
    display: none;
  }
  .nav-options.active {
    background: var(--marrom);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    align-content: center;
    padding-left: 0px;
  }

  .menu-icon {
    width: 45px;
    height: 45px;
  }
  .option {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vw;
    padding: 30px 0px;
  }
  .option a {
    text-align: center;
    padding: 1.8rem;
    width: 100%;
    display: table;
    color: var(--claro)
  }
  .option a:hover {
    color: var(--claro)
  }
}
.mobile-menu {
  display: block;
}
.dropdown {
  position: relative;
  display: inline-block;
  width: auto;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color:  var(--claro);
  width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-left: -16rem;
  padding: 20px;
  text-align: center;
  z-index: 1000;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.btn-login {
  width: 100%;
  background-color: var(--preto) /* Green */;
  border: none;
  color: var(--claro);
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
}
.btn-login:hover {
  opacity: 0.8;
}
.login .label-input {
  font-size: 14px;
  font-weight: 500;
  float: left;
}
.login input {
  width: 100%;
  padding: 10px 12px;
  margin: 8px 0;
  box-sizing: border-box;
}
.login a {
  float: right;
  font-size: 12px;
  color: var(--preto);
  padding-bottom: 10px;
}
