.carousel-root {
  width: 100%;
}
.central {
  display: flex;
  color: #000;
  margin-top: 50px;
  width: 99vw;
  flex-direction: row;
  justify-content: center;
}
h1 {
  color: #000;
}
.central-esq {
  padding: 10px;
}
.imagem-esq img {
  width: 100%;
}


.central-dir {
  margin-left: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.central-dir1 {
  width: 100%;
}
.central-dir-inf {
  width: 100%;
  display: flex;
  padding-top: 10px;
  padding-right: 10px;
}
.central-dir2 {
  width: 100%;
  padding-right: 10px;
}
.central-dir3 {
  width: 100%;
  padding-left: 10px;
}
.titleExtra {
  padding-left: 5rem;
  margin-top: 5rem;
}
.extras-home {
  display: flex;
  width: 99vw;
  flex-direction: row;
  padding: 10px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 5rem;
}
.item-extra {
  width: 400px;
  height: 550px;
  border-radius: 5px;
  background: #000;
  margin: 1rem;
}
.img-extra{
  width: 100%;
  height: 100%;
}