.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 50px 100px;
  box-sizing: border-box;
  height: auto;
}

.main-header {
  width: 100%;
  height: auto;
  text-align: center;
  padding: 10px 30px 10px 30px;
}
.titlePage {
  font-size: 25px;
  font-weight: 400;
  padding: 28px;
  width: 100%;
  text-align: center;
}
.subtitle {
  font-size: 12px;
  font-weight: 200;
  font-variant: normal;
  padding: 0px;
  letter-spacing: 1px;
}
.parte1 {
  background-image: url(../../images/quem-somos.jpeg);
  background-position: center;
  width: 100%;
  height: 400px;
  margin-bottom: 1.5em;
  clear: both;
  position: relative;
  background-attachment: fixed;
  background-repeat: no-repeat;
  display: flex;
}
.title-quem,
.text {
  margin: auto;
  line-height: normal;
}

.logo-quem {
  width: 90%;
  max-width: 420px;
  height: auto;
  display: block;
  margin: auto;
}
.sobre {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.sobre .text {
  width: 64%;
}
.text {
  padding: 0rem 0rem 0rem 2rem;
  line-height: 2;
  font-size: 1.2em;
}
.sobre p {
  margin: 0 0 10px;
}
.mapaQuem {
  float: right;
  width: 100%;
  max-width: 410px;
  max-height: 400px;
  height: auto;
  margin: auto;
}

.parte2 {
  margin-top: 3rem;
  margin-bottom: 5rem;
  height: 400px;
  clear: both;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.parte2 .left {
  background-image: url(../../images/25.jpg);
  background-position: left center;
  width: 50%;
  float: left;
  height: 400px;
  background-attachment: fixed;
  background-repeat: no-repeat;
  display: flex;
}
.left > .title > h3 {
  font-size: 3em;
  text-align: center;
  color: white;
  font-weight: bold;
  text-shadow: #00000029 0 0 10px;
}
.left .title {
  margin: auto;
  line-height: normal;
}

.parte2 > .right {
  width: 50%;
  float: right;
  background-attachment: fixed;
  background-repeat: no-repeat;
  display: flex;
  height: inherit;
}

@media (max-width: 1300px) {
  .main{
    align-items: center;
  }
.container{
  padding: 0% !important;
  margin: 0px;
}
.parte1{
  width: 100%;
}
.parte2{
  width: 100%;
  height: auto;
}
.sobre{
  width:100%
}
.sobre .text{
  padding: 3rem;
  width: 100%;
}
.parte2 .left{
  padding: 0;
  width:85%;
  height: 400px;
}
.parte2 .right{
  width: 100%;
  height: 400px;
}

}